import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IeltsUserExamDetailDto } from "components/Ielts/ExamBookingStatus/Datamapper/interface"
import { QeFormData } from "components/Ielts/SelfPrepPrePayment/DataMapper/intefaces"
import { PriceDTO } from "Interfaces/reducerTypes/TestPrepSchema"

export const ieltsEliteStateName = "IeltsElite"
export enum IeltsStageType {
  IELTS_DEMO_BOOKED = "IELTS_DEMO_BOOKED",
  IELTS_DEMO_ATTENDED = "IELTS_DEMO_ATTENDED",
  LS_USER_ACQUIRED = "LS_USER_ACQUIRED",
  IELTS_USER_ACQUIRED = "IELTS_USER_ACQUIRED",
  IELTS_PAID_USER = "IELTS_PAID_USER",
  IELTS_DEMO_MISSED = "IELTS_DEMO_MISSED",
  IELTS_LIY_PAID_USER = "IELTS_LIY_PAID_USER",
  IELTS_LIY_DEMO_BOOKED = "IELTS_LIY_DEMO_BOOKED",
  IELTS_LIY_DEMO_ATTENDED = "IELTS_LIY_DEMO_ATTENDED",
  IELTS_LIY_DEMO_MISSED = "IELTS_LIY_DEMO_MISSED",
  SAT_PAID_USER = "SAT_PAID_USER",
}
export type studentEligibilityType = {
  meetingLink: string
  reason: string
  slotType: number
}
type PostGetStageActionType = (stage: IeltsStageType | null) => void
type PostGetEligibilityActionType = (
  eligibility: studentEligibilityType | null,
) => void
export enum UserQeData {
  TYPE1 = "TYPE1",
  TYPE2 = "TYPE2",
  DEFAULT = "DEFAULT",
}
type PostFillIeltsQeFormActionType = (formData: object | null) => void
type PostFillIeltsEliteNudgeForm = (formData: object | null) => void
export interface IeltsEliteReducerSchema {
  isIeltsStageLoading: boolean
  ieltsStageError: string | null
  isStudentEligibilityLoading: boolean
  isStudentEligibilityError: string | null
  error: string | null
  isIeltsEliteQeFormOpen: boolean
  ieltsStage: IeltsStageType | null
  studentEligibility: studentEligibilityType | null
  showCongratsText: boolean
  postGetStageAction: null | PostGetStageActionType
  postGetEligibilityAction: null | PostGetEligibilityActionType
  postFillIeltsQeFormAction: null | PostFillIeltsQeFormActionType
  postFillIeltsEliteNudgeForm: null | PostFillIeltsEliteNudgeForm
  isQeModalOpen: boolean
  isQeFormSubmit: boolean
  qeFormData: QeFormData | null
  userQeData: UserQeData | null
  isExamBookedSubmit: boolean
  liyPaymentStatus: PriceDTO | null
  examBookedStatus: boolean | null
  inboundSalesNumber: number | string | null
  ptePaymentStatus: PriceDTO | null
}
const initialState: IeltsEliteReducerSchema = {
  isIeltsStageLoading: false,
  ieltsStageError: null,
  isStudentEligibilityLoading: false,
  isStudentEligibilityError: null,
  error: null,
  isIeltsEliteQeFormOpen: false,
  ieltsStage: null,
  studentEligibility: null,
  showCongratsText: false,
  postGetStageAction: null,
  postGetEligibilityAction: null,
  postFillIeltsQeFormAction: null,
  postFillIeltsEliteNudgeForm: null,
  isQeModalOpen: false,
  isQeFormSubmit: false,
  qeFormData: null,
  userQeData: null,
  isExamBookedSubmit: false,
  liyPaymentStatus: null,
  examBookedStatus: null,
  inboundSalesNumber: null,
  ptePaymentStatus: null,
}

export const fetchGetIeltsStage = createAction(
  `${ieltsEliteStateName}/fetchGetIeltsStage`,
)

export const fetchGetStudentEligibility = createAction(
  `${ieltsEliteStateName}/fetchGetStudentEligibility`,
)

export const fetchPostIeltsEliteNudgeForm = createAction<any>(
  `${ieltsEliteStateName}/fetchPostIeltsEliteNudgeForm`,
)

export const fetchPostIeltsQeform = createAction(
  `${ieltsEliteStateName}/fetchPostIeltsQeform`,
)

export const fetchPostDetailsIeltsClass = createAction<any>(
  `${ieltsEliteStateName}/fetchPostDetailsIeltsClass`,
)
//accepting a callback which will be called after this get api is called
export const fetchQeFormData = createAction<
  undefined | ((formData: QeFormData | null) => void)
>(`${ieltsEliteStateName}/fetchQeFormData`)

export const runIeltsQualificationEngineAction = createAction<boolean>(
  `${ieltsEliteStateName}/runIeltsQualificationEngine`,
)

export const fetchIeltsQeStatusSlice = createAction(
  `${ieltsEliteStateName}/fetchIeltsQeStatusSlice`,
)
export const postExamBookingDataSlice = createAction<IeltsUserExamDetailDto>(
  `${ieltsEliteStateName}/postExamBookingDataSlice`,
)
export const fetchLiyStatusSlice = createAction(
  `${ieltsEliteStateName}/fetchLiyStatusSlice`,
)
export const fetchExamBookedStatusSlice = createAction(
  `${ieltsEliteStateName}/fetchExamBookedStatusSlice`,
)

export const lsqTriggerSlice = createAction<string>(
  `${ieltsEliteStateName}/lsqTriggerSlice`,
)
export const lsqTriggerGetApiSlice = createAction(
  `${ieltsEliteStateName}/lsqTriggerGetApiSlice`,
)

export const fetchInboundSalesNumberSlice = createAction(
  `${ieltsEliteStateName}/fetchInboundSalesNumberSlice`,
)

export const fetchPteStatusSlice = createAction(
  `${ieltsEliteStateName}/fetchPteStatusSlice`,
)
export const fetchPostPteEliteNudgeFormSlice = createAction<any>(
  `${ieltsEliteStateName}/fetchPostPteEliteNudgeFormSlice`,
)

const ieltsEliteSlice = createSlice({
  name: ieltsEliteStateName,
  initialState,
  reducers: {
    toggleIsIeltsEliteQeFormOpen(state, action: PayloadAction<boolean>) {
      return { ...state, isIeltsEliteQeFormOpen: action.payload }
    },
    updateIeltsStage(state, action: PayloadAction<IeltsStageType>) {
      return { ...state, ieltsStage: action.payload }
    },
    updateStudentEligibility(
      state,
      action: PayloadAction<studentEligibilityType>,
    ) {
      return { ...state, studentEligibility: action.payload }
    },
    setIeltsStageLoading(state, action: PayloadAction<boolean>) {
      return { ...state, ieltsStageLoading: action.payload }
    },
    setIeltsStageError(state, action: PayloadAction<string>) {
      return { ...state, ieltsStageError: action.payload }
    },
    setStudentEligibilityLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isStudentEligibilityLoading: action.payload }
    },
    setStudentEligibilityError(state, action: PayloadAction<string>) {
      return { ...state, isStudentEligibilityError: action.payload }
    },
    setShowCongratsText(state, action: PayloadAction<boolean>) {
      return { ...state, showCongratsText: action.payload }
    },
    updatePostGetStageAction(
      state,
      action: PayloadAction<PostGetStageActionType>,
    ) {
      return { ...state, postGetStageAction: action.payload }
    },
    updatePostGetStudentEligibility(
      state,
      action: PayloadAction<PostGetEligibilityActionType>,
    ) {
      return { ...state, postGetEligibilityAction: action.payload }
    },
    updatePostFillIeltsQeFormAction(
      state,
      action: PayloadAction<PostFillIeltsQeFormActionType>,
    ) {
      return { ...state, postFillIeltsQeFormAction: action.payload }
    },
    updatePostFillIeltsEliteNudgeForm(
      state,
      action: PayloadAction<PostFillIeltsEliteNudgeForm>,
    ) {
      return { ...state, postFillIeltsEliteNudgeForm: action.payload }
    },
    setIsQeModalOpen(state, action: PayloadAction<boolean>) {
      return { ...state, isQeModalOpen: action.payload }
    },
    setIsQeFormSubmit(state, action: PayloadAction<boolean>) {
      return { ...state, isQeFormSubmit: action.payload }
    },
    setQeFormData(state, action: PayloadAction<QeFormData | null>) {
      return { ...state, qeFormData: action.payload }
    },
    setUserQeData(state, action: PayloadAction<UserQeData | null>) {
      return { ...state, userQeData: action.payload }
    },
    setIsExamBookedSubmit(state, action: PayloadAction<boolean>) {
      return { ...state, isExamBookedSubmit: action.payload }
    },
    setLiyPaymentStatus(state, action: PayloadAction<PriceDTO | null>) {
      return { ...state, liyPaymentStatus: action.payload }
    },
    setExamBookedStatus(state, action: PayloadAction<boolean | null>) {
      return { ...state, examBookedStatus: action.payload }
    },
    setInboundSalesNumber(
      state,
      action: PayloadAction<number | string | null>,
    ) {
      return { ...state, inboundSalesNumber: action.payload }
    },
    setPtePaymentStatus(state, action: PayloadAction<PriceDTO | null>) {
      return { ...state, ptePaymentStatus: action.payload }
    },
  },
})

export const {
  toggleIsIeltsEliteQeFormOpen,
  updateIeltsStage,
  updateStudentEligibility,
  setIeltsStageLoading,
  setIeltsStageError,
  setStudentEligibilityLoading,
  setStudentEligibilityError,
  setShowCongratsText,
  updatePostGetStageAction,
  updatePostGetStudentEligibility,
  updatePostFillIeltsQeFormAction,
  updatePostFillIeltsEliteNudgeForm,
  setIsQeModalOpen,
  setIsQeFormSubmit,
  setQeFormData,
  setUserQeData,
  setIsExamBookedSubmit,
  setLiyPaymentStatus,
  setExamBookedStatus,
  setInboundSalesNumber,
  setPtePaymentStatus,
} = ieltsEliteSlice.actions
export default ieltsEliteSlice.reducer
