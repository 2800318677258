import { IeltsUserExamDetailDto } from "components/Ielts/ExamBookingStatus/Datamapper/interface"
import api from "service/api"
import { mobileCheck } from "utils/browserUtils"

const fetchGetIeltsStage = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=ielts-elite/stage`)
    return res.data
  } catch (error) {
    return { success: false, data: null }
  }
}

const fetchPostIeltsEliteNudgeForm = async (formData: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=ielts-elite/details`,
      formData,
    )
    return res.data
  } catch (error) {
    return { success: false, data: null }
  }
}

const fetchGetStudentEligibility = async () => {
  try {
    const res = await api.get("/api/scholarRoute?path=student/eligibility")
    return res.data
  } catch (err: any) {
    return {
      success: false,
      data: null,
    }
  }
}

const fetchPostDetailsIeltsClass = async (values: Record<string, any>) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=workshop/ielts-class`,
      values,
    )
    return res.data
  } catch (err: any) {
    return {
      success: false,
      data: null,
    }
  }
}

const fetchQeFormDataApi = async () =>
  await api.get(`/api/scholarRoute?path=ielts-elite/details`)

const ieltsQualificationEngineApi = async (
  isTofuFlow?: boolean,
  source?: string,
) => {
  let headers = {}
  if (isTofuFlow) {
    headers = {
      "x-platform": mobileCheck() ? "TOFU_MWEB" : "TOFU_WEB",
    }
  } else {
    headers = {
      "x-platform": mobileCheck() ? "MWEB" : "WEB",
    }
  }
  if (source) {
    headers = {
      "x-platform": source,
    }
  }
  try {
    const res = await api.get(
      `/api/scholarRoute?path=ielts-elite/qualification/status`,
      {
        headers,
      },
    )
    return res.data
  } catch (e) {
    return { success: false, message: e }
  }
}

const fetchIeltsQeStatus = () =>
  api.get(`/api/scholarRoute?path=ielts-elite/qualification/fetch/status`)

const postExamBookingDataApi = (examBookedData: IeltsUserExamDetailDto) =>
  api.post(`/api/scholarRoute?path=ielts-elite/exam-details`, examBookedData)

const fetchLiyStatusApi = () =>
  api.get(`/api/scholarRoute?path=fee/liy/status`, {
    headers: {
      "X-API-VERSION": "2",
    },
  })

const fetchExamBookedStatusApi = () =>
  api.get(`/api/scholarRoute?path=ielts-elite/exam-details/form-visibility`)

const lsqTriggerApi = async (lsqSource: string) =>
  api.post(
    `/api/scholarRoute?path=ielts-elite/dashboard/lsq-event?bookingSource=${lsqSource}`,
  )
const menaEventApi = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=ielts-elite/meta-event-mena`,
    )
    return res.data
  } catch (error) {
    return { success: false, message: "Error sending MENA event" }
  }
}

const lsqTriggerGetApi = () =>
  api.get(`/api/scholarRoute?path=ielts-elite/inbound-lsq`)

const fetchInboundSalesNumberApi = () =>
  api.get(`/api/scholarRoute?path=ielts-elite/inbound-call-number`)

const fetchPteStatusApi = () => api.get(`/api/scholarRoute?path=fee/pte/status`)

const fetchPostPteEliteNudgeFormApi = (formData: any) =>
  api.post(`/api/scholarRoute?path=pte-elite/details`, formData)

export const ieltsEliteApis = {
  fetchPostIeltsEliteNudgeForm,
  fetchPostDetailsIeltsClass,
  fetchGetIeltsStage,
  fetchGetStudentEligibility,
  fetchQeFormDataApi,
  ieltsQualificationEngineApi,
  fetchIeltsQeStatus,
  postExamBookingDataApi,
  fetchLiyStatusApi,
  fetchExamBookedStatusApi,
  lsqTriggerApi,
  menaEventApi,
  lsqTriggerGetApi,
  fetchInboundSalesNumberApi,
  fetchPteStatusApi,
  fetchPostPteEliteNudgeFormApi,
}
