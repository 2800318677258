import { apoloClient } from "apollo/apollo"
import { FETCH_EVENT_DATA } from "apollo/queries/events"
import api from "service/api"
import {
  checkEventsToShow,
  checkTofuVisibility,
} from "hooks/events/useUpcomingEventsData"
import { getTokenCookie } from "lib/auth-cookies-new"
import isEmpty from "lodash/isEmpty"
import { store } from "redux/store"
import { CounsellingApp } from "Interfaces/reducerTypes/SpotCounsellingSchema"
import { getCookie } from "utils/cookie-utiils"

const fetchUpcommingEventsData = async () => {
  try {
    const state = store.getState()
    let preferredCountry = ""
    let bookedEvents: any

    if (getTokenCookie()) {
      preferredCountry = await fetchPreferredCountry()
      bookedEvents = await api.get(`/api/scholarRoute?path=event/registered`)
    }

    const studentStage = state.spotCounselling.stage

    const upcomingEventsRes = api.get(
      `/api/scholarRoute?path=event/upcoming/30`,
    )
    const [res] = await Promise.all([upcomingEventsRes])

    let bookedList: never[] = []
    if (bookedEvents?.data?.success) {
      bookedList = bookedEvents?.data?.data
    }
    if (res.data.success) {
      if (res?.data?.data) {
        const apiEventData: any = []
        const eventsSlug: any = []

        res?.data?.data.forEach((elem: any) => {
          if (elem.slots) {
            elem.slots.forEach((data: any) => {
              if (data.slug) {
                eventsSlug.push(data.slug)
                apiEventData.push(data)
              }
            })
          }
        })

        const slugObj: any = {}

        eventsSlug.forEach((elen: any) => {
          if (!slugObj[elen]) {
            slugObj[elen] = 1
          }
        })

        const newSlugArray = Object.keys(slugObj)

        if (newSlugArray.length === 0) {
          return
        }

        const res2 = await apoloClient.query({
          query: FETCH_EVENT_DATA,
          variables: { slugArray: newSlugArray },
        })

        const cmsEventsData: any = res2?.data?.events

        if (apiEventData && cmsEventsData) {
          const finalData: any = []

          for (let i = 0; i < apiEventData.length; i++) {
            for (let j = 0; j < cmsEventsData.length; j++) {
              if (cmsEventsData[j]?.slug == apiEventData[i]?.slug) {
                const data = { ...cmsEventsData[j], ...apiEventData[i] }
                finalData.push(data)
              }
            }
          }

          const toShow: any = []
          if (!isEmpty(studentStage)) {
            finalData.map((event: any) => {
              const check = checkEventsToShow(
                event.batchId,
                event.event_tags_v2,
                studentStage as string,
                preferredCountry ?? ("" as string),
                bookedList,
              )
              if (check) {
                toShow.push(event)
              }
            })
          } else {
            finalData.map((event: any) => {
              const check = checkTofuVisibility(
                event.batchId,
                event.event_tags_v2,
                bookedList,
              )
              if (check) {
                toShow.push(event)
              }
            })
          }
          return { upcomingEvents: toShow, bookedEvents: bookedList }
        } else
          return {
            error: "Failed to get upcoming events",
            data: { bookedEvents: bookedList, upcomingEvents: [] },
          }
      } else return { error: "failed", data: null }
    } else return { bookedEvents: bookedList, upcomingEvents: [] }
  } catch (err: any) {
    return { bookedEvents: [], upcomingEvents: [] }
  }
}

const getExpertData = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=v2/workshop/MASTER_CLASS/0/12`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const getProfileLayout = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/profile-layout`)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const saveData = async (values: any) => {
  let res
  try {
    res = await api.post(
      `/api/scholarRoute?path=lead/country-form-spot`,
      values,
    )
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
  if (res?.data?.success) {
    return true
  } else {
    return res?.data?.message
  }
}

export const getAbVariant = async (experiment: string) => {
  try {
    const userId = getCookie(null, "user_id")
    const payload = {
      guid: userId?.toString(),
      preUserId: userId,
    }
    const res = await api.post(
      `/api/abExperiments?path=getAb/${experiment}`,
      payload,
    )
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

export const checkEligibilty = async () => {
  try {
    const res = await api.get("/api/scholarRoute?path=student/eligibility")
    if (res.data.success) return res.data
    else return res.data.message
  } catch (err: any) {
    return err.message
  }
}

const checkReQualificationEligibility = async () => {
  try {
    const res = await api.get(
      "/api/scholarRoute?path=student/eligibility/requalification",
    )
    if (res.data.success) return res.data
    else return res.data.message
  } catch (err: any) {
    return err.message
  }
}

const getStage = async (): Promise<void> => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/stage`)
    if (res.data.success) {
      return res.data
    } else {
      return res.data.message
    }
  } catch (err: any) {
    return err.message || "Something went wrong"
  }
}

const getAppNudgeEligibility = async (): Promise<void> => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=student/eligibility/app/nudge`,
    )
    if (res.data.success) {
      return res.data
    } else {
      return res.data.message
    }
  } catch (err: any) {
    return err.message || "Something went wrong"
  }
}

const getAppNudgeData = async (): Promise<CounsellingApp | {}> => {
  try {
    const res = await api.get(`${process.env.CMS_URL}/idle-user-app-nudges`)
    return res.data?.[0]
  } catch (err: any) {
    return {}
  }
}

const getStageCustom = async (): Promise<any> => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/stage`)
    if (res.data.success) {
      return res.data
    } else {
      return "LS_LEAD_CAPTURED"
    }
  } catch (err: any) {
    return "LS_LEAD_CAPTURED"
  }
}

const activateLead = async (lead: string): Promise<any> => {
  try {
    const res = await api.get(`/api/scholarRoute?path=user/reviveUser/${lead}`)
    return res?.data?.success
  } catch (err: any) {
    return false
  }
}

const reviveUserEligibility = async (lead: string): Promise<any> => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=user/reviveUser/eligibility/${lead}`,
    )
    return res?.data?.data
  } catch (err: any) {
    return false
  }
}

const getGoalPlanData = async (): Promise<void> => {
  try {
    const res = await api.get(`/api/scholarRoute?path=lead/goalPlan/pre-users`)
    if (res.data.success) {
      return res.data
    } else {
      return res.data.message
    }
  } catch (err: any) {
    return err.message || "Something went wrong"
  }
}

const fetchSpotCounsellingSlot = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=counselling/slots/live/30`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return res?.data?.message
    }
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const confirmSpotCounsellingSlot = async (payload: any) => {
  const { batchId } = payload
  const data = {
    utm_campaign: payload.utmData.utm_campaign || "",
    utm_source: payload.utmData.utm_source || "",
    utm_term: payload.utmData.utm_term || "",
    utm_medium: payload.utmData.utm_medium || "",
    gclid: payload.utmData?.gclid || "",
    adName: payload.utmData?.adName || "",
    campaign_type: payload.utmData.campaign_type || "",
    form_id: payload.utmData.formId?.length ? payload.utmData.formId : null,
    ad_id: payload.utmData.ad_id || "",
    referer: payload.utmData.referer || "",
  }

  try {
    const res = await api.post(
      `/api/scholarRoute?path=counselling/register/${batchId}`,
      data,
    )
    if (res.data.success) {
      return res.data
    } else {
      return res.data.message
    }
  } catch (err: any) {
    console.log(err)
    return err.message
  }
}

const confirmRescheduleSlot = async (payload: any) => {
  const { batchId, utmData, meetingId } = payload
  const data = {
    utm_campaign: utmData.utm_campaign || "",
    utm_source: utmData.utm_source || "",
    utm_term: utmData.utm_term || "",
    utm_medium: utmData.utm_medium || "",
    gclid: utmData?.gclid || "",
    adName: utmData?.adName || "",
    campaign_type: utmData.campaign_type || "",
    form_id: utmData.formId?.length ? utmData.formId : null,
    ad_id: utmData.ad_id || "",
  }

  try {
    const res = await api.post(
      `/api/scholarRoute?path=counselling/register/${batchId}&reschedule=true&meetingId=${meetingId}`,
      data,
    )
    if (res.data.success) {
      return res.data
    } else {
      return res.data.message
    }
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const fetchSpotProfile = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/spot-profile`)
    if (res?.data?.success) {
      return res.data
    } else {
      return res?.data?.message
    }
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please refresh the page")
  }
}

export const fetchPreferredCountry = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=student/preferred-country`,
    )
    if (res.data.success) {
      return res.data?.data
    } else return null
  } catch (err: any) {
    console.log(err)
    return null
  }
}

export const submitSpotProfile = async (values: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=student/spot-profile`,
      values,
    )
    if (res?.data?.success) {
      return res
    } else {
      return res?.data?.message
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
}

export const spotCounsellingApi = {
  getExpertData,
  checkEligibilty,
  saveData,
  getStage,
  fetchSpotCounsellingSlot,
  confirmSpotCounsellingSlot,
  confirmRescheduleSlot,
  fetchSpotProfile,
  fetchPreferredCountry,
  submitSpotProfile,
  getGoalPlanData,
  fetchUpcommingEventsData,
  getProfileLayout,
  getStageCustom,
  checkReQualificationEligibility,
  activateLead,
  reviveUserEligibility,
  getAppNudgeEligibility,
  getAppNudgeData,
  getAbVariant,
}
