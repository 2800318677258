import { useEffect, useState } from "react"
import { mobileCheck } from "utils/browserUtils"

declare global {
  interface Window {
    opera: any
    MSStream: any
  }
}

export default () => {
  const [deviceInfo, setDeviceInfo] = useState({
    isIos: false,
    isAndroid: false,
    isMobile: false,
    isTablet: false,
    isLoading: true,
  })

  const getDeviceInfoByWidth = () => {
    const payload = {
      isMobile: false,
      isTablet: false,
    }
    if (typeof window != "undefined") {
      if (window.innerWidth <= 700) {
        payload.isMobile = true
      } else if (window.innerWidth > 700 && window.innerWidth < 1000) {
        payload.isTablet = true
      }
      setDeviceInfo((prevState) => ({ ...prevState, ...payload }))
    }
  }

  function handleResize(): void {
    getDeviceInfoByWidth()
  }

  useEffect(() => {
    const userAgent =
      window.navigator.userAgent || navigator.vendor || window.opera
    const isTablet =
      window.matchMedia &&
      window.matchMedia(
        "(min-device-width: 768px) and (max-device-width: 1024px)",
      ).matches

    if (/android/i.test(userAgent)) {
      setDeviceInfo((config) => ({
        ...config,
        isAndroid: true,
      }))
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceInfo((config) => ({ ...config, isIos: true }))
      if (/iPad/.test(userAgent)) {
        setDeviceInfo((config) => ({ ...config, isTablet }))
      }
    }

    if (mobileCheck()) {
      setDeviceInfo((config) => ({
        ...config,
        isMobile: true,
        isLoading: false,
      }))
      if (isTablet) {
        setDeviceInfo((config) => ({ ...config, isTablet }))
      }
    }
    setDeviceInfo((config) => ({
      ...config,
      isLoading: false,
    }))
    window.addEventListener("resize", handleResize)
    handleResize()
    return (): void => window.removeEventListener("resize", handleResize)
  }, [])

  return deviceInfo
}
